/**
 * @generated SignedSource<<15ea69dc249e207950a9d80c9cc41c31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksUseCurrentArticleIdsArticle$data = {
  readonly entityId: string;
  readonly entityUuid: string;
  readonly " $fragmentType": "hooksUseCurrentArticleIdsArticle";
};
export type hooksUseCurrentArticleIdsArticle$key = {
  readonly " $data"?: hooksUseCurrentArticleIdsArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksUseCurrentArticleIdsArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksUseCurrentArticleIdsArticle"
};

(node as any).hash = "3635a2a8a82bcbe711069f8c19ac4cda";

export default node;
